import type { AxiosPromise } from 'axios'
import axios from '@axios'
import type { UserData } from '@cabinet/modules/auth/types/Auth.Userdata'

export default class AppHttpApiService {
  private static endPoints = {
    initApp: '/v1/init',
  }

  public static init(): AxiosPromise<UserData> {
    return axios.get(this.endPoints.initApp)
  }
}
