import { useAppStateStore } from '@/app/store'
import api from '@/app/api/AppHttpApiService'
import type { UserData } from '@cabinet/modules/auth/types/Auth.Userdata'
import SentryLogger from '@/plugins/sentry/SentryLogger'

export function initApp(): Promise<UserData> {
  const store = useAppStateStore()

  store.setIsAppFetching(true)

  return new Promise((resolve, reject) => {
    api.init()
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        SentryLogger.captureScopeException(error, {
          message: 'Проблема с инициализацией',
        })
        reject(error)
      })
      .finally(() => {
        store.setIsAppFetching(false)
      })
  })
}
