<script setup lang="ts">
import { VDialog } from 'vuetify/components/VDialog'
import { useDisplay } from 'vuetify'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [String, Number],
    default: '375',
  },
  height: {
    type: String,
    default: 'auto',
  },
  title: {
    type: String,
    default: '',
  },
  headerClass: {
    type: [String, Array, Object],
    default: '',
  },
  bodyClass: {
    type: [String, Array, Object],
    default: '',
  },
  contentClass: {
    type: [String, Array, Object],
    default: '',
  },
  isDividerVisible: {
    type: Boolean,
    default: true,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String as PropType<'white' | 'neutral-100'>,
    default: 'white',
  },
})

const emits = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

const { mobile } = useDisplay()

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => { emits('update:modelValue', value) },
})

const displayingComponent = computed(() => mobile.value ? VDialog : VDialog)
</script>

<template>
  <component
    :is="displayingComponent"
    v-model="isModalOpen"
    :scrollable="scrollable"
    :width="fullscreen ? '100%' : width"
    :height="fullscreen ? '100%' : height"
    :fullscreen="fullscreen"
  >
    <template #default>
      <slot name="content">
        <v-card
          class="qb-card-in-modal"
          :class="[{ 'qb-card-in-modal_rounded': !fullscreen }, bgColor !== 'white' ? `qb-card-in-modal_${bgColor}` : '', bodyClass]"
        >
          <div
            v-if="!hideHeader"
            :class="[{ 'mb-5': isDividerVisible }, headerClass]"
          >
            <slot name="header">
              <div class="d-flex justify-space-between align-center">
                <h2 class="text-dark qb-text-h2">
                  {{ title }}
                </h2>
                <slot name="closeButton">
                  <v-icon
                    class="text-dark"
                    icon="custom-close"
                    size="25"
                    @click="isModalOpen = false"
                  />
                </slot>
              </div>
            </slot>
          </div>
          <v-divider
            v-if="isDividerVisible"
            color="neutral-100"
            thickness="2"
            class="mb-5"
          />
          <v-card-text :class="contentClass">
            <slot />
          </v-card-text>
          <v-card-actions v-if="$slots.footer">
            <slot name="footer" />
          </v-card-actions>
        </v-card>
      </slot>
    </template>
  </component>
</template>

<style lang="scss">
.qb-card-in-modal {
  &_rounded {
    border-radius: 20px !important;
  }
  &_neutral-100 {
    background: rgb(var(--v-theme-neutral-100)) !important;
  }
  & .v-card-text {
    padding: 0 !important;
  }
  & .v-card-actions {
    padding: 0 !important;
  }
}
</style>
