import { setupLayouts } from 'virtual:generated-layouts'
import type { RouteRecordName } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import { isUserLoggedIn } from '@cabinet/router/utils'
import { RouterGuardHelper } from '@cabinet/router/utils/Router.guardHelper'
import ROUTES_NAMES from '@cabinet/router/constants/Router.routeNames'

const router = createRouter({
  history: createWebHistory('/cabinet'),
  routes: [
    ...setupLayouts(routes),
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to && !RouterGuardHelper.checkIsRouteNameInAuthorizationRoutes(to.name as RouteRecordName) && !isLoggedIn) {
    return next({ name: ROUTES_NAMES.login, replace: true })
  }

  if (isLoggedIn) {
    return RouterGuardHelper.handleRouteChangeForLoginUser(to, from, next)
  }

  return next()
})
export default router
