export const useAppStateStore = defineStore('appStateStore', () => {
  const isAppFetching = ref(false)

  // actions
  function setIsAppFetching(value: boolean) {
    isAppFetching.value = value
  }

  return {
    isAppFetching,
    setIsAppFetching,
  }
})
