/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/cabinet/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/cabinet/router'
import '@core-scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { defineRules } from '@/plugins/vee-validate'
import i18n from '@/plugins/i18n'
import yMaps from '@/plugins/yandex-maps'
import VCalendar from 'v-calendar'
import { datePickerOptions } from '@styles/vuetify/components/datepicker/options'
import { initSentry } from '@/plugins/sentry'

loadFonts()
defineRules()

// Create vue app
const app = createApp(App)

initSentry(app, router)
// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(yMaps)
app.use(layoutsPlugin)
app.use(VCalendar, datePickerOptions)

// Mount vue app
app.mount('#app')
