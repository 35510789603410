import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { AUTHORIZATION_ROUTES_NAMES } from '@cabinet/router/constants/Router.authorizationRoutesNames'
import { ADMIN_ROUTES_NAMES } from '@cabinet/router/constants/Router.adminRoutesNames'
import { initApp } from '@/app/helpers/App.init'
import ROUTES_NAMES from '@cabinet/router/constants/Router.routeNames'
import { useUserStore } from '@cabinet/modules/user/stores'
import type { User } from '@/cabinet/modules/user/types/User'

export class RouterGuardHelper {
  public static checkIsRouteNameInAuthorizationRoutes(routeName: RouteRecordName): boolean {
    return AUTHORIZATION_ROUTES_NAMES.includes(routeName)
  }

  public static checkIsRouteNameInAdminRoutes(routeName: RouteRecordName): boolean {
    return ADMIN_ROUTES_NAMES.includes(routeName)
  }

  public static checkSettingsPage(to: RouteLocationNormalized, next: NavigationGuardNext, userFromInit?: User) {
    let user: User

    if (userFromInit) {
      user = userFromInit
    }
    else {
      const store = useUserStore()

      user = store.user
    }

    if (!user.isAdmin && this.checkIsRouteNameInAdminRoutes(to.name)) {
      return next({ name: ROUTES_NAMES.home, replace: true })
    }

    return next()
  }

  public static async handleRouteChangeForLoginUser(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
    if (from.name !== ROUTES_NAMES.home && this.checkIsRouteNameInAuthorizationRoutes(to.name)) {
      return next({ name: ROUTES_NAMES.home })
    }
    if (!from.name || this.checkIsRouteNameInAuthorizationRoutes(from.name)) {
      const user = await initApp() as User
      const store = useUserStore()

      store.setUser(user)

      RouterGuardHelper.checkSettingsPage(to, next, user)
    }

    RouterGuardHelper.checkSettingsPage(to, next)
  }
}
