import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export const initSentry = (app: App, router?: Router): void => {
  const environment = process.env.APP_ENV
  if (environment === 'prod') {
    Sentry.init({
      app,
      dsn: process.env.SENTRY_FRONTEND_DSN,
      release: process.env.APP_VERSION,
      environment,
      tracesSampleRate: 1.0,
      tracingOptions: {
        trackComponents: true,
      },
      integrations: [
        new Sentry.BrowserTracing(router && {
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      debug: false,
      attachStacktrace: true,
      normalizeDepth: 10,
    })
  }
}
