<script lang="ts" setup>
import { useLayouts } from '@cabinet/modules/navigation'
import type { NavLink } from '@cabinet/modules/navigation/types'
import { isNavLinkActive } from '@cabinet/modules/navigation/utils'
import { useUserStore } from '@cabinet/modules/user/stores'

defineProps<{
  item: NavLink
}>()

const store = useUserStore()

const { width: windowWidth } = useWindowSize()

const { isVerticalNavMini } = useLayouts()

const hideTitleAndBadge = isVerticalNavMini(windowWidth)

const profileImageLink = computed(() => store.user.avatar?.link)
</script>

<template>
  <li
    class="d-flex nav-list-item mb-5"
    :class="{ disabled: item.disable }"
  >
    <component
      :is="isNavLinkActive(item, $router) ? 'div' : 'RouterLink'"
      :to="item.to"
      class="w-100"
      :class="{ 'nav-list-active-item': isNavLinkActive(item, $router) }"
    >
      <div
        v-if="item.id === 0"
        class="avatar-padding rounded-pill d-flex"
        :class="isNavLinkActive(item, $router) ? 'bg-dark' : 'text-dark'"
      >
        <v-avatar
          :image="profileImageLink"
          icon="custom-person"
          color="neutral-100"
          :size="isNavLinkActive(item, $router) ? '36' : '40'"
        />
      </div>
      <div
        v-else
        class="pa-2 rounded-pill d-flex"
        :class="isNavLinkActive(item, $router) ? 'bg-dark' : 'text-dark'"
      >
        <v-icon :icon="item.icon" />
      </div>
      <transition-group name="transition-slide-x">
        <!-- 👉 Title -->
        <h3
          v-show="!hideTitleAndBadge"
          class="text-dark qb-text-h3 whitespace-no-wrap"
          :class="{ disabled: item.disable }"
        >
          {{ item.title }}
        </h3>
      </transition-group>
    </component>
  </li>
</template>

<style lang="scss">
.nav-list-item {
  min-height: 40px;

  &>a, &>div, &>button {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &.disabled {
    opacity: var(--v-disabled-opacity);
    pointer-events: none;
  }
}
.nav-list-active-item {
  background: rgb(var(--v-theme-secondary-light));
  border-radius: 48px;
  padding: 4px;
}
.avatar-padding{
  padding: 2px;
}
</style>
