import i18n from '@/plugins/i18n'

const { t } = i18n.global

export const VAutocomplete = {
  noDataText: t('common.autoCompleteEmptyData'),
  variant: 'outlined',
  density: 'compact',
  hideDetails: 'auto',
  menuProps: {
    contentClass: 'app-autocomplete__content v-autocomplete__content',
  },
  VChip: {
    color: 'primary',
    label: true,
  },

}
