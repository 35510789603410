<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@/app/composable/useThemeConfig'
import CustomToaster from '@/ui/toasts/components/CustomToaster.vue'
import { hexToRgb } from '@/common/utils'
import NoInternetConnectionWarning from '@/common/components/warnings/NoInternetConnectionWarning.vue'

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl, handleSkinChanges } = useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()
</script>

<template>
  <v-locale-provider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <v-app :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <no-internet-connection-warning />
      <custom-toaster />
      <router-view />
    </v-app>
  </v-locale-provider>
</template>
