export const datePickerOptions = {
  locales: {
    'ru-RU': {
      firstDayOfWeek: 2,
      masks: {
        weekdays: 'WW',
      },
    },
  },
}
