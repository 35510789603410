const ROUTES_NAMES = {
  home: 'index',
  login: 'login',
  signup: 'signup',
  restorePassword: 'reset-password',
  forgotPassword: 'forgot-password',
  profile: 'profile',
  users: 'users',
  clients: 'clients',
  ingredients: 'ingredients',
  places: 'places',
  orders: 'orders',
  calendar: 'calendar',
  reports: 'reports',
  menu: 'menu',
  services: 'services',
  templates: 'templates',
  settings: 'settings',
} as const

export default ROUTES_NAMES
