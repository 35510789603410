<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const offlineMessageVisible = ref<boolean>(false)

const handleOnline = () => {
  offlineMessageVisible.value = false
}

const handleOffline = () => {
  offlineMessageVisible.value = true
}

useEventListener('online', handleOnline)

useEventListener('offline', handleOffline)
</script>

<template>
  <v-dialog
    v-model="offlineMessageVisible"
    persistent
  >
    <v-card>
      <div class="d-flex justify-center">
        {{ t('common.noInternet') }}
      </div>
    </v-card>
  </v-dialog>
</template>
