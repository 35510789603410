import { VTextField } from '@/plugins/vuetify/components/inputs/VTextField'
import { VTextarea } from '@/plugins/vuetify/components/inputs/VTextarea'
import VCard from '@/plugins/vuetify/components/cards/VCard'
import { VBtn } from '@/plugins/vuetify/components/buttons/VBtn'
import { IconBtn } from '@/plugins/vuetify/components/buttons/IconBtn'
import { VRadio } from '@/plugins/vuetify/components/radio-buttons/VRadio'
import { VCheckbox } from '@/plugins/vuetify/components/checkboxes/VCheckbox'
import { VSelect } from '@/plugins/vuetify/components/selects/VSelect'
import { VAutocomplete } from '@/plugins/vuetify/components/autocompletes/VAutocomplete'

export default {
  IconBtn,
  VAlert: {
    density: 'comfortable',
    VBtn: {
      color: undefined,
    },
  },
  VAutocomplete,
  VBadge: {
    // set v-badge default color to primary
    color: 'primary',
  },
  VBtn,
  VChip: {
    size: 'small',
  },
  VExpansionPanel: {
    expandIcon: 'tabler-chevron-right',
    collapseIcon: 'tabler-chevron-right',
  },
  VExpansionPanelTitle: {
    expandIcon: 'tabler-chevron-right',
    collapseIcon: 'tabler-chevron-right',
  },
  VList: {
    density: 'comfortable',
    VCheckboxBtn: {
      density: 'compact',
    },
  },
  VPagination: {
    activeColor: 'primary',
    density: 'comfortable',
    variant: 'tonal',
  },
  VTabs: {
    // set v-tabs default color to primary
    color: 'primary',
    density: 'comfortable',
    VSlideGroup: {
      showArrows: true,
    },
  },
  VTooltip: {
    // set v-tooltip default location to top
    location: 'top',
    class: 'qb-tooltip',
  },
  VCheckboxBtn: {
    color: 'primary',
  },
  VRadio,
  VCheckbox,
  VRadioGroup: {
    color: 'primary',
    density: 'comfortable',
    hideDetails: 'auto',
  },
  VSelect,
  VRangeSlider: {
    // set v-range-slider default color to primary
    color: 'primary',
    trackColor: 'rgb(var(--v-theme-on-surface),0.06)',
    trackSize: 6,
    thumbSize: 7,
    density: 'comfortable',
    thumbLabel: true,
    hideDetails: 'auto',
  },
  VRating: {
    // set v-rating default color to primary
    color: 'warning',
  },
  VProgressCircular: {
    // set v-progress-circular default color to primary
    color: 'primary',
  },
  VProgressLinear: {
    height: 12,
    roundedBar: true,
    rounded: true,
    bgColor: 'rgb(var(--v-theme-on-surface))',
  },
  VSlider: {
    // set v-slider default color to primary
    color: 'primary',
    trackColor: 'rgb(var(--v-theme-on-surface),0.06)',
    hideDetails: 'auto',
    thumbSize: 7,
    trackSize: 6,
  },
  VTextField,
  VCombobox: {
    variant: 'outlined',
    density: 'compact',
    hideDetails: 'auto',
    VChip: {
      color: 'primary',
      label: true,
    },
  },
  VFileInput: {
    variant: 'outlined',
    density: 'compact',
    color: 'primary',
    hideDetails: 'auto',
  },
  VTextarea,
  VSwitch: {
    // set v-switch default color to primary
    inset: true,
    color: 'primary',
    hideDetails: 'auto',
  },
  VTimeline: {
    lineThickness: 1,
  },
  VCard,
  VDataTable: {
    VDataTableFooter: {
      VBtn: {
        density: 'comfortable',
        color: 'default',
      },
    },
  },
}
