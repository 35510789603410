<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { VerticalNavLink } from '@cabinet/modules/navigation/components'
import type { NavLink } from '@cabinet/modules/navigation/types'
import { isNavLinkActive } from '@cabinet/modules/navigation/utils'
import ModalOrBottomSheet from '@/ui/modals/ModalOrBottomSheet.vue'
import { useUserStore } from '@cabinet/modules/user/types/UserModule'
import ROUTES_NAMES from '@cabinet/router/constants/Router.routeNames'

const props = defineProps({
  navItems: {
    type: Array as PropType<Array<NavLink>>,
    required: true,
  },
})

const store = useUserStore()

const { xs } = useDisplay()

const router = useRouter()

const isDrawerOpen = ref(false)

const profileImageLink = computed(() => store.user.avatar?.link)

const fullName = computed(() => {
  const nameParts = store.user.fullName.split(' ')
  if (nameParts.length >= 3) {
    return `${nameParts[0]} ${nameParts[1]}`
  }
  else if (nameParts.length === 2) {
    return `${nameParts[0]} ${nameParts[1]}`
  }
  else {
    return nameParts[0] || ''
  }
},
)

const filteredItems = computed(() =>
  xs.value ? props.navItems.slice(0, 4) : props.navItems,
)

const filteredItemsForDrawer = computed(() => {
  const itemsForDrawer = props.navItems.slice(4)
  const profileId = 0
  // TODO как появятся шаблоны, нужно будет изменить
  const profileIndex = itemsForDrawer.findIndex(item => item.id === profileId)

  itemsForDrawer.splice(profileIndex, 1)

  return itemsForDrawer
},
)
</script>

<template>
  <v-bottom-navigation
    class="qb-bottom-navigation"
    height="80"
  >
    <component
      :is="isNavLinkActive(item, $router) ? 'div' : 'RouterLink'"
      v-for="(item, id) in filteredItems"
      :key="id"
      class="d-flex align-center justify-center"
      :to="item.to"
    >
      <div
        class="pa-1  rounded-pill d-flex"
        :class="{ 'bg-secondary-light': isNavLinkActive(item, $router) }"
      >
        <div
          v-if="item.id === 0"
          class="avatar-padding rounded-pill d-flex"
          :class="isNavLinkActive(item, $router) ? 'bg-dark' : 'text-dark'"
        >
          <v-avatar
            :image="profileImageLink"
            icon="custom-person"
            color="neutral-100"
            :size="isNavLinkActive(item, $router) ? '36' : '40'"
          />
        </div>
        <div
          v-else
          class="pa-2 rounded-pill d-flex"
          :class="isNavLinkActive(item, $router) ? 'bg-dark' : 'text-dark'"
        >
          <v-icon
            size="24"
            :icon="item.icon"
          />
        </div>
      </div>
    </component>
    <div
      v-if="xs"
      class="d-flex align-center justify-center"
    >
      <div
        class="d-flex  pa-1 rounded-pill"
        :class="{ 'bg-secondary-light': isDrawerOpen }"
      >
        <button
          class="pa-2 rounded-pill d-flex"
          :class="isDrawerOpen ? 'bg-dark' : 'text-dark'"
        >
          <v-icon
            size="24"
            icon="custom-burger"
            @click="isDrawerOpen = !isDrawerOpen"
          />
        </button>
      </div>
    </div>
  </v-bottom-navigation>
  <modal-or-bottom-sheet v-model="isDrawerOpen">
    <perfect-scrollbar
      tag="ul"
      class="nav-items"
      :options="{ wheelPropagation: false }"
    >
      <li class="d-flex nav-list-item mb-5">
        <div>
          <v-avatar
            :image="store.user.avatar?.link"
            icon="custom-person"
            color="neutral-100"
            size="40"
          />
          <span
            v-if="
              fullName"
            class="text-dark qb-text-h3 cursor-pointer"
            @click="router.push({ name: ROUTES_NAMES.profile }); isDrawerOpen = false "
          >
            {{ fullName }}
          </span>
        </div>
      </li>
    </perfect-scrollbar>
    <perfect-scrollbar
      tag="ul"
      class="nav-items"
      :options="{ wheelPropagation: false }"
    >
      <vertical-nav-link
        v-for="(item, index) in filteredItemsForDrawer"
        :key="index"
        class="mb-2 mt-2"
        :item="item"
      />
    </perfect-scrollbar>
  </modal-or-bottom-sheet>
</template>

<style lang="scss">
.qb-bottom-navigation {
  padding: 20px;

  .v-bottom-navigation__content {
    justify-content: space-between
  }
}
</style>
