<script lang="ts" setup>
import DefaultLayoutWithVerticalNav from '@cabinet/components/DefaultLayoutWithVerticalNav.vue'
import { useSkins } from '@/app/composable/useSkins'
import { useThemeConfig } from '@/app/composable/useThemeConfig'

// @layouts plugin
import { AppContentLayoutNav } from '@cabinet/modules/navigation/enums'

const { appContentLayoutNav } = useThemeConfig()

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()
</script>

<template>
  <template v-if="appContentLayoutNav === AppContentLayoutNav.Vertical">
    <default-layout-with-vertical-nav v-bind="layoutAttrs" />
  </template>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/_default-layout";
</style>
