import { email, image, integer, length, max, max_value, min, min_value, numeric, regex, required, size, url } from '@vee-validate/rules'

import i18n from '@/plugins/i18n'

const { t } = i18n.global

export const customRequired = (value: unknown, _: never, ctx: any) => {
  return required(value) || t('validation.messages.required', { label: ctx.label })
}

export const customEmail = (value: unknown, _: never, ctx: any) => {
  return email(value) || t('validation.messages.email', { label: ctx.label })
}

export const customPhone = (value: unknown, _: never, ctx: any) => {
  if (value === null || value === '') {
    return true
  }
  const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

  return (typeof value === 'string' && phoneRegex.test(value)) || t('validation.messages.phone', { label: ctx.label })
}
export const validateLogin = (value: unknown, _: never, ctx: any) => {
  if (value === undefined || value === null || (typeof value === 'string' && value.length === 0)) { return true }
  if (email(value) || customPhone(value, _, ctx) === true) {
    return true
  }

  return t('validation.messages.validateLogin', { label: ctx.label })
}

export const isPasswordConfirmed = (value: unknown, [param]: string, ctx: any) => {
  return value === ctx.form[param] || t('validation.messages.confirmPassword')
}

export const requiredIf = (value: unknown, condition: [(value: any) => boolean], ctx: any) => {
  return condition[0](ctx.form) ? customRequired(value, null as never, ctx) : true
}

export const customMin = (value: unknown, params: Array<number>, ctx: any) => {
  return min(value, params) || t('validation.messages.min', { label: ctx.label, min: params[0] })
}

export const customMinValue = (value: unknown, params: number, ctx: any) => {
  return min_value(value, [params]) || t('validation.messages.minValue', { label: ctx.label, min: params[0] })
}

export const customMax = (value: unknown, params: Array<number>, ctx: any) => {
  return max(value, params) || t('validation.messages.max', { label: ctx.label, max: params[0] })
}

export const customMaxValue = (value: unknown, params: number, ctx: any) => {
  return max_value(value, [params]) || t('validation.messages.maxValue', { label: ctx.label, max: params[0] })
}

export const customLength = (value: unknown, params: Array<number>, ctx: any) => {
  return length(value, params) || t('validation.messages.length', { label: ctx.label, length: params[0] })
}

export const isPassword = (value: unknown, _: never, ctx: any) => {
  const MIN_PASSWORD_LENGTH = 6

  return min(value, [MIN_PASSWORD_LENGTH]) || t('validation.messages.min', { label: ctx.label, min: MIN_PASSWORD_LENGTH })
}

export const isNumeric = (value: unknown, _: never, ctx: any) => {
  return numeric(value) || t('validation.messages.numeric', { label: ctx.label })
}
export const isInteger = (value: unknown, _: never, ctx: any) => {
  return integer(value) || t('validation.messages.integer', { label: ctx.label })
}

export const isDecimal = (value: unknown, digitsAfterDecimal: number, ctx: any) => {
  if (value === null || value === '' || value === undefined) {
    return true
  }

  const valueToString = typeof value === 'number' ? value.toString() : value

  const numberRegex = new RegExp(`^[-+]?\\d+(\\.\\d{1,${digitsAfterDecimal}})?$`)

  return numberRegex.test((valueToString as string).trim()) || t('validation.messages.decimal', { label: ctx.label, digitsAfterDecimal })
}
export const isFullName = (value: unknown, _: never, ctx: any) => {
  const fullNameRegex = /\S/

  return regex(value, [fullNameRegex]) || t('validation.messages.required', { label: ctx.label })
}

export const isImageCorrect = (value: unknown, _: never, ctx: any) => {
  const MAX_IMAGE_SIZE = 10 * 1000
  if (!image(value)) {
    return t('validation.messages.image.fileType', { label: ctx.label })
  }
  if (!size(value, [MAX_IMAGE_SIZE])) {
    return t('validation.messages.image.size', { size: MAX_IMAGE_SIZE / 1000 })
  }

  return true
}

export const isURL = (value: unknown, _: never, ctx: any) => {
  const urlRegex = /^https?:\/\//

  return url(value, [urlRegex]) || t('validation.messages.url', { label: ctx.label })
}

export const isTimeFormatCorrect = (value: string) => {
  if (value === null || value === '' || typeof value === 'undefined') {
    return true
  }
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

  return timeRegex.test(value.trim()) || t('validation.messages.time')
}
export const isCorrectSubdomain = (value: string) => {
  if (value === null || value === '' || typeof value === 'undefined') {
    return true
  }
  const subdomainRegex = /^(?!.*\.)(?!event-\d+$)/

  return (subdomainRegex.test(value.trim())) || t('settings.landing.form.subdomain.errorMessage')
}
