<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import type { NavLink } from '@cabinet/modules/navigation/types'

// Components
import VerticalNavLayout from '@cabinet/modules/navigation/components/VerticalNavLayout.vue'
import ROUTES_NAMES from '@cabinet/router/constants/Router.routeNames'
import { useUserStore } from '@cabinet/modules/user/stores'
import { useThemeConfig } from '@/app/composable/useThemeConfig'

// @layouts plugin

const { appRouteTransition, isLessThanOverlayNavBreakpoint } = useThemeConfig()

const { width: windowWidth } = useWindowSize()

const store = useUserStore()

const { t } = useI18n()

const getProfileName = (fio: string) => {
  const [lastName, firstName, ...rest] = fio.split(' ')

  if (!firstName) {
    return lastName
  }

  const lastNameInitial = `${lastName.charAt(0)}.`

  return `${firstName} ${lastNameInitial}`
}

const profileName = computed(() => getProfileName(store.user.fullName))

const navItems = computed<Array<NavLink>>(() => {
  const defaultLinks = [
    {
      id: 0,
      title: profileName.value || t('navMenu.links.profile'),
      to: { name: ROUTES_NAMES.profile },
      icon: 'custom-person',
      disable: false,
    },
    {
      id: 1,
      title: t('navMenu.links.orders'),
      to: { name: ROUTES_NAMES.orders },
      icon: 'custom-tableware',
    },
    {
      id: 2,
      title: t('navMenu.links.calendar'),
      to: { name: ROUTES_NAMES.calendar },
      icon: 'custom-calendar',
      disable: false,
    },
    {
      id: 3,
      title: t('navMenu.links.templates'),
      to: { name: ROUTES_NAMES.templates },
      icon: 'custom-copy',
      disable: false,
    },
    {
      id: 4,
      title: t('navMenu.links.places'),
      to: { name: ROUTES_NAMES.places },
      icon: 'custom-places',
      disable: false,
    },
    {
      id: 5,
      title: t('navMenu.links.reports'),
      to: { name: ROUTES_NAMES.reports },
      icon: 'custom-report-box',
      disable: false,
    },
    {
      id: 6,
      title: t('navMenu.links.menu'),
      to: { name: ROUTES_NAMES.menu },
      icon: 'custom-food-menu',
    },
    {
      id: 7,
      title: t('navMenu.links.ingredients'),
      to: { name: ROUTES_NAMES.ingredients },
      icon: 'custom-meat',
      disable: false,
    },
    {
      id: 8,
      title: t('navMenu.links.services'),
      to: { name: ROUTES_NAMES.services },
      icon: 'custom-entertainment',
      disable: false,
    },
    {
      id: 9,
      title: t('navMenu.links.clients'),
      to: { name: ROUTES_NAMES.clients },
      icon: 'custom-people',
      disable: false,
    },
    {
      id: 10,
      title: t('navMenu.links.workers'),
      to: { name: ROUTES_NAMES.users },
      icon: 'custom-necktie',
      disable: false,
    },
  ] as Array<NavLink>

  const settingsLink = {
    id: 11,
    title: t('navMenu.links.settings'),
    to: { name: ROUTES_NAMES.settings },
    icon: 'custom-settings',
    disable: false,
  } as NavLink

  if (store.user.isAdmin) {
    defaultLinks.push(settingsLink)
  }

  return defaultLinks
})
</script>

<template>
  <vertical-nav-layout :nav-items="navItems">
    <template
      v-if="windowWidth > 1020"
      #navbar="{ toggleVerticalOverlayNavActive }"
    >
      <div class="d-flex h-100 align-center">
        <icon-btn
          v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
          id="vertical-nav-toggle-btn"
          class="ms-n3"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <v-icon
            size="26"
            icon="tabler-menu-2"
          />
        </icon-btn>

        <v-spacer />
      </div>
    </template>

    <!-- 👉 Pages -->
    <router-view v-slot="{ Component }">
      <transition
        :name="appRouteTransition"
        mode="out-in"
      >
        <div class="d-flex flex-column flex-grow-1">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>

    <!-- 👉 Footer -->
    <template #footer>
      <footer />
    </template>
    <!-- 👉 Customizer -->
    <!-- <TheCustomizer /> -->
  </vertical-nav-layout>
</template>
