const __pages_import_0__ = () => import("/resources/ts/cabinet/pages/profile/index.vue");
const __pages_import_1__ = () => import("/resources/ts/cabinet/pages/menu/index.vue");
const __pages_import_2__ = () => import("/resources/ts/cabinet/pages/users.vue");
const __pages_import_3__ = () => import("/resources/ts/cabinet/pages/templates.vue");
const __pages_import_4__ = () => import("/resources/ts/cabinet/pages/signup.vue");
const __pages_import_5__ = () => import("/resources/ts/cabinet/pages/settings.vue");
const __pages_import_6__ = () => import("/resources/ts/cabinet/pages/services.vue");
const __pages_import_7__ = () => import("/resources/ts/cabinet/pages/reports.vue");
const __pages_import_8__ = () => import("/resources/ts/cabinet/pages/places.vue");
const __pages_import_9__ = () => import("/resources/ts/cabinet/pages/orders.vue");
const __pages_import_10__ = () => import("/resources/ts/cabinet/pages/login.vue");
const __pages_import_11__ = () => import("/resources/ts/cabinet/pages/ingredients.vue");
import __pages_import_12__ from "/resources/ts/cabinet/pages/index.vue";
const __pages_import_13__ = () => import("/resources/ts/cabinet/pages/forgot-password.vue");
const __pages_import_14__ = () => import("/resources/ts/cabinet/pages/clients.vue");
const __pages_import_15__ = () => import("/resources/ts/cabinet/pages/calendar.vue");
const __pages_import_16__ = () => import("/resources/ts/cabinet/pages/[...all].vue");
const __pages_import_17__ = () => import("/resources/ts/cabinet/pages/reset-password/[token].vue");

const routes = [{"name":"profile","path":"/profile","component":__pages_import_0__,"props":true},{"name":"menu","path":"/menu","component":__pages_import_1__,"props":true},{"name":"users","path":"/users","component":__pages_import_2__,"props":true},{"name":"templates","path":"/templates","component":__pages_import_3__,"props":true},{"name":"signup","path":"/signup","component":__pages_import_4__,"props":true,"meta":{"layout":"blank"}},{"name":"settings","path":"/settings","component":__pages_import_5__,"props":true},{"name":"services","path":"/services","component":__pages_import_6__,"props":true},{"name":"reports","path":"/reports","component":__pages_import_7__,"props":true},{"name":"places","path":"/places","component":__pages_import_8__,"props":true},{"name":"orders","path":"/orders","component":__pages_import_9__,"props":true},{"name":"login","path":"/login","component":__pages_import_10__,"props":true,"meta":{"layout":"blank"}},{"name":"ingredients","path":"/ingredients","component":__pages_import_11__,"props":true},{"name":"index","path":"/","component":__pages_import_12__,"props":true},{"name":"forgot-password","path":"/forgot-password","component":__pages_import_13__,"props":true,"meta":{"layout":"blank"}},{"name":"clients","path":"/clients","component":__pages_import_14__,"props":true},{"name":"calendar","path":"/calendar","component":__pages_import_15__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_16__,"props":true},{"name":"reset-password-token","path":"/reset-password/:token","component":__pages_import_17__,"props":true,"meta":{"layout":"blank"}}];

export default routes;