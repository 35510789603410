import { defineRule } from 'vee-validate'
import {
  customEmail,
  customLength,
  customMax,
  customMaxValue,
  customMin,
  customMinValue,
  customPhone,
  customRequired,
  isCorrectSubdomain,
  isDecimal,
  isFullName,
  isImageCorrect,
  isInteger,
  isNumeric,
  isPassword,
  isPasswordConfirmed,
  isTimeFormatCorrect,
  isURL,
  requiredIf,
  validateLogin,

} from '@/plugins/vee-validate/rules'

export function defineRules() {
  defineRule('length', customLength)
  defineRule('min', customMin)
  defineRule('minValue', customMinValue)
  defineRule('max', customMax)
  defineRule('maxValue', customMaxValue)
  defineRule('numeric', isNumeric)
  defineRule('integer', isInteger)
  defineRule('required', customRequired)
  defineRule('email', customEmail)
  defineRule('phone', customPhone)
  defineRule('validateLogin', validateLogin)
  defineRule('confirm', isPasswordConfirmed)
  defineRule('required_if', requiredIf)
  defineRule('password', isPassword)
  defineRule('fullName', isFullName)
  defineRule('image', isImageCorrect)
  defineRule('url', isURL)
  defineRule('time', isTimeFormatCorrect)
  defineRule('subdomain', isCorrectSubdomain)
  defineRule('decimal', isDecimal)
}
