<script setup lang="ts">
import logo from '@images/logo.svg'

defineProps({
  isLink: {
    type: Boolean,
    default: true,
  },
  withTitle: {
    type: Boolean,
    default: true,
  },
})

const routeName = '/'
const appName = process.env.APP_NAME
</script>

<template>
  <component
    :is="isLink ? 'router-link' : 'div'"
    :to="isLink ? routeName : undefined"
    class="d-flex align-center"
  >
    <v-avatar
      size="40"
      :image="logo"
    />
    <h3
      v-if="withTitle"
      class="ms-3 text-dark qb-text-h3"
    >
      {{ appName }}
    </h3>
  </component>
</template>
