import i18n from '@/plugins/i18n'

const { t } = i18n.global

export const VSelect = {
  noDataText: t('common.autoCompleteEmptyData'),
  variant: 'outlined',
  density: 'compact',
  hideDetails: 'auto',
}
