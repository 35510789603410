import type { User } from '@cabinet/modules/user/types/User'

type UserState = {
  user: User
}
export const useUserStore = defineStore('user', {
  state: () => ({
    user: JSON.parse(localStorage.getItem('userData') as string) || {} as User,
  }) as UserState,
  actions: {
    setUser(user: User) {
      localStorage.setItem('userData', JSON.stringify(user))
      this.user = user
    },
  },
})
